import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      scrollToTop: false,
      contextReturn: 'none',
      loading: null,
      currentStep: 1,
      stepList: [
        { id: 1, label: '1' },
        { id: 2, label: '2' },
        { id: 3, label: '3' },
      ]
    }
  },
  actions: {
    setScrollToTop({ commit }, payload) {
      commit('SET_SCROLL_TO_TOP', payload)
    },
    setContextReturn({ commit }, payload) {
      commit('SET_CONTEXT_RETURN', payload)
    },
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload)
    },
    setCurrentStep({ commit }, payload) {
      commit('SET_CURRENT_STEP', payload)
    },
    setStepList({ commit }, payload) {
      commit('SET_STEP_LIST', payload)
    },
  },
  getters: {
    getScrollToTop(state) {
      return state.scrollToTop
    },
    getContextReturn(state) {
      return state.contextReturn
    },
    getLoading(state) {
      return state.loading
    },
    getCurrentStep(state) {
      return state.currentStep
    },
    getStepList(state) {
      return state.stepList
    }
  },
  mutations: {
    SET_SCROLL_TO_TOP(state, payload) {
      state.scrollToTop = payload
    },
    SET_CONTEXT_RETURN(state, payload) {
      state.contextReturn = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_CURRENT_STEP(state, payload) {
      state.currentStep = payload
    },
    SET_STEP_LIST(state, payload) {
      state.stepList = payload
    }
  }
})

export default defineNuxtPlugin(() => {
  return {
    provide: {
      store
    }
  }
})