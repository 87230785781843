export default function useSessionStorage() {
  // Salva valor no armazenamento da sessão
  function setItem(key, value = {}) {
    sessionStorage.setItem(key, JSON.stringify(value))
  }

  // Recupera valor do armazenamento da sessão
  function getItem(key) {
    return JSON.parse(sessionStorage.getItem(key))
  }

  // Limpa valores do armazenamento da sessão
  function clearItem(key = null) {
    if (key) {
      sessionStorage.removeItem(key)
      return
    }
    sessionStorage.clear()
  }

  return {
    setItem,
    getItem,
    clearItem
  }
}