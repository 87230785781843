import { defineStore } from 'pinia'

const useScreenStore = defineStore('screen', {
  state: () => ({
    isLoadingScreen: null,
    isRequestingScreen: null,
  }),
  actions: {
    setLoadingScreen(payload = false) {
      this.isLoadingScreen = payload
    },
    setRequestingScreen(payload = false) {
      this.isRequestingScreen = payload
    }
  }
})

export { useScreenStore }