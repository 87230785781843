export default defineNuxtPlugin(({ $pinia }) => {
  function PiniaInterceptors({ store }) {
    // Intercepta alterações globais de estado (state)
    store.$subscribe((mutation) => {
      console.log(`[🍍 SUBSCRIBE -> ${mutation.storeId}]: ${mutation.type}`)
    })

    // Intercepta ações globais de estados (actions)
    store.$onAction((action) => {
      console.log(`[🍍 ACTION -> ${action.store.$id}]: ${action.name}`)
    })

    // Posso retornar propriedades aqui como parte do estado global
    // return { creationTime: new Date() }
  }

  $pinia.use(PiniaInterceptors)
})