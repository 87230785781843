import { defineStore } from 'pinia'

const useSettingsStore = defineStore('settings', {
  state: () => ({
    infoSettings: {
      hasActiveModal: null,
      date: null,
    },
    storageSettings: {
      hasActiveModal: null,
      permissions: null,
    },
    simulatorSettings: {
      userData: null,
      lastStep: null,
      token: ''
    },
    accountSettings: {
      userData: null,
      lastStep: null,
      token: ''
    }
  }),
  actions: {
    setInfoSettings(payload) {
      this.infoSettings = { ...this.infoSettings, ...payload }
    },
    setStorageSettings(payload) {
      this.storageSettings = { ...this.storageSettings, ...payload }
    },
    setSimulatorSettings(payload) {
      this.simulatorSettings = { ...this.simulatorSettings, ...payload }
    },
    setAccountSettings(payload) {
      this.accountSettings = { ...this.accountSettings, ...payload }
    }
  },
  persist: true
})

export { useSettingsStore }