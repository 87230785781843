import { default as como_45contratarcXGzipvTIkMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/como-contratar.vue?macro=true";
import { default as index90rGQ73Zr0Meta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/index.vue?macro=true";
import { default as open_45financeUyr027N4eUMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/open-finance.vue?macro=true";
import { default as portabilidadey6dfQFuiB8Meta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/portabilidade.vue?macro=true";
import { default as refinanciamentoElzV96FrkCMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/refinanciamento.vue?macro=true";
import { default as alerta_45antifraudeZqPPkvD5HQMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/alerta-antifraude.vue?macro=true";
import { default as datasT7eY27q2rMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ativacao-cadastro/data.js?macro=true";
import { default as indexiDTCf8sbA8Meta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ativacao-cadastro/index.vue?macro=true";
import { default as _91_46_46_46slug_93GJTKPM5UObMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/blog/[...slug].vue?macro=true";
import { default as indexFEeYDbIi40Meta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/blog/index.vue?macro=true";
import { default as datafLpuJ0WRkZMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/criacao-de-acesso/data.js?macro=true";
import { default as indexFA2T53FX1hMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/criacao-de-acesso/index.vue?macro=true";
import { default as indexs1mhPv1CQSMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/index.vue?macro=true";
import { default as como_45contratarzBShHpV0zBMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/como-contratar.vue?macro=true";
import { default as dataXwiqJ5HtITMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/data.js?macro=true";
import { default as open_45financeA8NSBPuulAMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/open-finance.vue?macro=true";
import { default as portabilidade0gmwjEC0f8Meta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/portabilidade.vue?macro=true";
import { default as refinanciamentoZccj9aaQfXMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/refinanciamento.vue?macro=true";
import { default as dataHZttiopvCcMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/alerta-antifraude/data.js?macro=true";
import { default as datawHhpAutab5Meta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/data.js?macro=true";
import { default as datad1i38b6PLPMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/lgpd/data.js?macro=true";
import { default as datafFxCtW2kWiMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/politica-de-privacidade/data.js?macro=true";
import { default as dataH07vc1tSdnMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/termos-de-uso/data.js?macro=true";
import { default as LGPDkBnDptdmnwMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/LGPD.vue?macro=true";
import { default as politica_45de_45privacidadeY2sUAh42lwMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/politica-de-privacidade.vue?macro=true";
import { default as portability_45errorGSMg9I8GdRMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/portability-error.vue?macro=true";
import { default as quem_45somoseJ1WqxiRjvMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/quem-somos.vue?macro=true";
import { default as indexPgl1OHqygDMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/simulador-de-emprestimo/index.vue?macro=true";
import { default as termos_45de_45usoGHlDIHPDMbMeta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/termos-de-uso.vue?macro=true";
import { default as vantagens_45e_45diferenciaisjQmO7zfwu4Meta } from "D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/vantagens-e-diferenciais.vue?macro=true";
export default [
  {
    name: "ajuda-como-contratar",
    path: "/ajuda/como-contratar",
    meta: como_45contratarcXGzipvTIkMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/como-contratar.vue").then(m => m.default || m)
  },
  {
    name: "ajuda",
    path: "/ajuda",
    meta: index90rGQ73Zr0Meta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: "ajuda-open-finance",
    path: "/ajuda/open-finance",
    meta: open_45financeUyr027N4eUMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/open-finance.vue").then(m => m.default || m)
  },
  {
    name: "ajuda-portabilidade",
    path: "/ajuda/portabilidade",
    meta: portabilidadey6dfQFuiB8Meta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/portabilidade.vue").then(m => m.default || m)
  },
  {
    name: "ajuda-refinanciamento",
    path: "/ajuda/refinanciamento",
    meta: refinanciamentoElzV96FrkCMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ajuda/refinanciamento.vue").then(m => m.default || m)
  },
  {
    name: "alerta-antifraude",
    path: "/alerta-antifraude",
    meta: alerta_45antifraudeZqPPkvD5HQMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/alerta-antifraude.vue").then(m => m.default || m)
  },
  {
    name: "ativacao-cadastro-data",
    path: "/ativacao-cadastro/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ativacao-cadastro/data.js").then(m => m.default || m)
  },
  {
    name: "ativacao-cadastro",
    path: "/ativacao-cadastro",
    meta: indexiDTCf8sbA8Meta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/ativacao-cadastro/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexFEeYDbIi40Meta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "criacao-de-acesso-data",
    path: "/criacao-de-acesso/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/criacao-de-acesso/data.js").then(m => m.default || m)
  },
  {
    name: "criacao-de-acesso",
    path: "/criacao-de-acesso",
    meta: indexFA2T53FX1hMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/criacao-de-acesso/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexs1mhPv1CQSMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-como-contratar",
    path: "/institutional/ajuda/como-contratar",
    meta: como_45contratarzBShHpV0zBMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/como-contratar.vue").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-data",
    path: "/institutional/ajuda/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-open-finance",
    path: "/institutional/ajuda/open-finance",
    meta: open_45financeA8NSBPuulAMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/open-finance.vue").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-portabilidade",
    path: "/institutional/ajuda/portabilidade",
    meta: portabilidade0gmwjEC0f8Meta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/portabilidade.vue").then(m => m.default || m)
  },
  {
    name: "institutional-ajuda-refinanciamento",
    path: "/institutional/ajuda/refinanciamento",
    meta: refinanciamentoZccj9aaQfXMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/ajuda/refinanciamento.vue").then(m => m.default || m)
  },
  {
    name: "institutional-alerta-antifraude-data",
    path: "/institutional/alerta-antifraude/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/alerta-antifraude/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-data",
    path: "/institutional/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-lgpd-data",
    path: "/institutional/lgpd/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/lgpd/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-politica-de-privacidade-data",
    path: "/institutional/politica-de-privacidade/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/politica-de-privacidade/data.js").then(m => m.default || m)
  },
  {
    name: "institutional-termos-de-uso-data",
    path: "/institutional/termos-de-uso/data",
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/institutional/termos-de-uso/data.js").then(m => m.default || m)
  },
  {
    name: "LGPD",
    path: "/LGPD",
    meta: LGPDkBnDptdmnwMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/LGPD.vue").then(m => m.default || m)
  },
  {
    name: "politica-de-privacidade",
    path: "/politica-de-privacidade",
    meta: politica_45de_45privacidadeY2sUAh42lwMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/politica-de-privacidade.vue").then(m => m.default || m)
  },
  {
    name: "teste",
    path: "/portability-error",
    meta: portability_45errorGSMg9I8GdRMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/portability-error.vue").then(m => m.default || m)
  },
  {
    name: "quem-somos",
    path: "/quem-somos",
    meta: quem_45somoseJ1WqxiRjvMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/quem-somos.vue").then(m => m.default || m)
  },
  {
    name: "simulador-de-emprestimo",
    path: "/simulador-de-emprestimo",
    meta: indexPgl1OHqygDMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/simulador-de-emprestimo/index.vue").then(m => m.default || m)
  },
  {
    name: "termos-de-uso",
    path: "/termos-de-uso",
    meta: termos_45de_45usoGHlDIHPDMbMeta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/termos-de-uso.vue").then(m => m.default || m)
  },
  {
    name: "vantagens-e-diferenciais",
    path: "/vantagens-e-diferenciais",
    meta: vantagens_45e_45diferenciaisjQmO7zfwu4Meta || {},
    component: () => import("D:/Servicos/Microsoft/AgentFin/_work/1/s/pages/vantagens-e-diferenciais.vue").then(m => m.default || m)
  }
]